<div class="row" [formGroup]="datesForm">
    <div class="col-6 form-group">
        <label for="deletionDate">{{'deletionDate' | i18n}}</label>
        <ng-template #deletionDateCustom>
            <ng-container [ngSwitch]="browserPath">
                <ng-container *ngSwitchCase="'firefox'">
                    <div class="d-flex justify-content-around">
                        <input id="deletionDateCustomFallback" class="form-control mt-1" type="date"
                            name="DeletionDateFallback" formControlName="fallbackDeletionDate" required
                            placeholder="MM/DD/YYYY" [readOnly]="disableSend" data-date-format="mm/dd/yyyy">
                        <input id="deletionTimeCustomFallback" class="form-control mt-1 ml-1" type="time"
                            name="DeletionTimeDate" formControlName="fallbackDeletionTime" required
                            placeholder="HH:MM AM/PM" [readOnly]="disableSend">
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'safari'">
                    <div class="d-flex justify-content-around">
                        <input id="deletionDateCustomFallback" class="form-control mt-1" type="date"
                            name="DeletionDateFallback" formControlName="fallbackDeletionDate" required
                            placeholder="MM/DD/YYYY" [readOnly]="disableSend" data-date-format="mm/dd/yyyy">
                        <select id="deletionTimeCustomFallback" class="form-control mt-1 ml-1" [required]="!editMode"
                            formControlName="fallbackDeletionTime" name="SafariDeletionTime">
                            <option *ngFor="let o of safariDeletionTimePresetOptions" [ngValue]="o.twentyFourHour">{{o.twelveHour}}</option>
                        </select>
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <input id="deletionDateCustom" class="form-control mt-1" type="datetime-local"
                        name="DeletionDate" formControlName="defaultDeletionDateTime" required
                        placeholder="MM/DD/YYYY HH:MM AM/PM" [readOnly]="disabled">
                </ng-container>
            </ng-container>
        </ng-template>
        <div *ngIf="!editMode">
            <select id="deletionDate" name="SelectedDeletionDatePreset"
                formControlName="selectedDeletionDatePreset" class="form-control" required>
                <option *ngFor="let o of deletionDatePresets" [ngValue]="o.value">{{o.name}}
                </option>
            </select>
            <ng-container *ngIf="selectedDeletionDatePreset.value === 0">
                <ng-container *ngTemplateOutlet="deletionDateCustom">
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="editMode">
            <ng-container *ngTemplateOutlet="deletionDateCustom">
            </ng-container>
        </div>
        <div class="form-text text-muted small">{{'deletionDateDesc' | i18n}}</div>
    </div>
    <div class="col-6 form-group">
        <div class="d-flex">
            <label for="expirationDate">{{'expirationDate' | i18n}}</label>
            <a href="#" appStopClick (click)="clearExpiration()" class="ml-auto"
                *ngIf="editMode && !disabled">
                {{'clear' | i18n}}
            </a>
        </div>
        <ng-template #expirationDateCustom>
            <ng-container [ngSwitch]="browserPath">
                <div *ngSwitchCase="'firefox'" class="d-flex justify-content-around">
                    <input id="expirationDateCustomFallback" class="form-control mt-1" type="date"
                        name="ExpirationDateFallback" formControlName="fallbackExpirationDate" [required]="!editMode"
                        placeholder="MM/DD/YYYY" [readOnly]="disabled" data-date-format="mm/dd/yyyy">
                    <input id="expirationTimeCustomFallback" class="form-control mt-1 ml-1" type="time"
                        name="ExpirationTimeFallback" formControlName="fallbackExpirationTime" [required]="!editMode"
                        placeholder="HH:MM AM/PM" [readOnly]="disabled">
                </div>
                <!-- non-default cases are not showing up -->
                <div *ngSwitchCase="'safari'" class="d-flex justify-content-around">
                    <input id="expirationDateCustomFallback" class="form-control mt-1" type="date"
                        name="ExpirationDateFallback" formControlName="fallbackExpirationDate" [required]="!editMode"
                        placeholder="MM/DD/YYYY" [readOnly]="disabled" data-date-format="mm/dd/yyyy">
                    <select id="expirationTimeCustomFallback" class="form-control mt-1 ml-1" [required]="!editMode"
                        formControlName="fallbackExpirationTime" name="SafariExpirationTime">
                        <option *ngFor="let o of safariExpirationTimePresetOptions" [ngValue]="o.twentyFourHour">{{o.twelveHour}}</option>
                    </select>
                </div>
                <ng-container *ngSwitchDefault>
                    <input id="expirationDateCustom" class="form-control mt-1" type="datetime-local"
                        name="ExpirationDate" formControlName="defaultExpirationDateTime" placeholder="MM/DD/YYYY HH:MM AM/PM" 
                        [readOnly]="disabled">
                </ng-container>
            </ng-container>
        </ng-template>
        <div *ngIf="!editMode">
            <select id="expirationDate" name="SelectedExpirationDatePreset"
                formControlName="selectedExpirationDatePreset" class="form-control" required>
                <option *ngFor="let o of expirationDatePresets" [ngValue]="o.value">{{o.name}}
                </option>
            </select>
            <ng-container *ngIf="selectedExpirationDatePreset.value === 0">
                <ng-container *ngTemplateOutlet="expirationDateCustom">
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="editMode">
            <ng-container *ngTemplateOutlet="expirationDateCustom">
            </ng-container>
        </div>
        <div class="form-text text-muted small">{{'expirationDateDesc' | i18n}}</div>
    </div>
</div>
