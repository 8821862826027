<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="userAddEditTitle">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title" id="userAddEditTitle">
                    {{title}}
                    <small class="text-muted" *ngIf="name">{{name}}</small>
                </h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="loading">
                <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                <span class="sr-only">{{'loading' | i18n}}</span>
            </div>
            <div class="modal-body" *ngIf="!loading">
                <ng-container *ngIf="!editMode">
                    <p>{{'providerInviteUserDesc' | i18n}}</p>
                    <div class="form-group mb-4">
                        <label for="emails">{{'email' | i18n}}</label>
                        <input id="emails" class="form-control" type="text" name="Emails" [(ngModel)]="emails" required
                            appAutoFocus>
                        <small class="text-muted">{{'inviteMultipleEmailDesc' | i18n : '20'}}</small>
                    </div>
                </ng-container>
                <h3>
                    {{'userType' | i18n}}
                    <a target="_blank" rel="noopener" appA11yTitle="{{'learnMore' | i18n}}"
                        href="https://bitwarden.com/help/article/user-types-access-control/#user-types">
                        <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                    </a>
                </h3>
                <div class="form-check mt-2 form-check-block">
                    <input class="form-check-input" type="radio" name="userType" id="userTypeServiceUser"
                        [value]="userType.ServiceUser" [(ngModel)]="type">
                    <label class="form-check-label" for="userTypeServiceUser">
                        {{'serviceUser' | i18n}}
                        <small>{{'serviceUserDesc' | i18n}}</small>
                    </label>
                </div>
                <div class="form-check mt-2 form-check-block">
                    <input class="form-check-input" type="radio" name="userType" id="userTypeProviderAdmin"
                        [value]="userType.ProviderAdmin" [(ngModel)]="type">
                    <label class="form-check-label" for="userTypeProviderAdmin">
                        {{'providerAdmin' | i18n}}
                        <small>{{'providerAdminDesc' | i18n}}</small>
                    </label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
                    {{'cancel' | i18n}}
                </button>
                <div class="ml-auto">
                    <button #deleteBtn type="button" (click)="delete()" class="btn btn-outline-danger"
                        appA11yTitle="{{'delete' | i18n}}" *ngIf="editMode" [disabled]="deleteBtn.loading"
                        [appApiAction]="deletePromise">
                        <i class="fa fa-trash-o fa-lg fa-fw" [hidden]="deleteBtn.loading" aria-hidden="true"></i>
                        <i class="fa fa-spinner fa-spin fa-lg fa-fw" [hidden]="!deleteBtn.loading"
                            title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
