<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="resetPasswordTitle">
    <div class="modal-dialog" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
            <div class="modal-header">
                <h2 class="modal-title" id="resetPasswordTitle">
                    {{'resetPassword' | i18n}}
                    <small class="text-muted" *ngIf="name">{{name}}</small>
                </h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-callout type="warning">{{'resetPasswordLoggedOutWarning' | i18n: loggedOutWarningName}}
                </app-callout>
                <app-callout type="info" [enforcedPolicyOptions]="enforcedPolicyOptions"
                    enforcedPolicyMessage="{{'resetPasswordMasterPasswordPolicyInEffect' | i18n}}"
                    *ngIf="enforcedPolicyOptions">
                </app-callout>
                <div class="row">
                    <div class="col form-group">
                        <div class="d-flex">
                            <label for="newPassword">{{'newPassword' | i18n}}</label>
                            <div class="ml-auto d-flex">
                                <a href="#" class="d-block mr-2 fa-icon-above-input" appStopClick
                                    appA11yTitle="{{'generatePassword' | i18n}}" (click)="generatePassword()">
                                    <i class="fa fa-lg fa-fw fa-refresh" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="input-group mb-1">
                            <input id="newPassword" class="form-control text-monospace" appAutofocus
                                type="{{showPassword ? 'text' : 'password'}}" name="NewPassword"
                                [(ngModel)]="newPassword" required appInputVerbatim autocomplete="new-password"
                                (input)="updatePasswordStrength()">
                            <div class="input-group-append">
                                <button type="button" class="btn btn-outline-secondary"
                                    appA11yTitle="{{'toggleVisibility' | i18n}}" (click)="togglePassword()">
                                    <i class="fa fa-lg" aria-hidden="true"
                                        [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                                </button>
                                <button type="button" class="btn btn-outline-secondary"
                                    appA11yTitle="{{'copyPassword' | i18n}}" (click)="copy(newPassword)">
                                    <i class="fa fa-lg fa-clone" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <app-password-strength [score]="masterPasswordScore" [showText]="true">
                        </app-password-strength>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'cancel' |
                    i18n}}</button>
            </div>
        </form>
    </div>
</div>
