<form #form (ngSubmit)="submit()" class="container" [appApiAction]="initiateSsoFormPromise" ngNativeValidate>
    <div class="row justify-content-md-center mt-5">
        <div class="col-5">
            <img src="images/logo-dark@2x.png" class="logo mb-2" alt="Logo">
            <div class="card d-block mt-4">
                <div class="card-body" *ngIf="loggingIn">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    {{'loading' | i18n}}
                </div>
                <div class="card-body" *ngIf="!loggingIn">
                    <p>{{'ssoLogInWithOrgIdentifier' | i18n}}</p>
                    <div class="form-group">
                        <label for="identifier">{{'organizationIdentifier' | i18n}}</label>
                        <input id="identifier" class="form-control" type="text" name="Identifier"
                            [(ngModel)]="identifier" required appAutofocus>
                    </div>
                    <hr>
                    <div class="d-flex">
                        <button type="submit" class="btn btn-primary btn-block btn-submit" [disabled]="form.loading">
                            <span>
                                <i class="fa fa-sign-in" aria-hidden="true"></i> {{'logIn' | i18n}}
                            </span>
                            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                        </button>
                        <a routerLink="/" class="btn btn-outline-secondary btn-block ml-2 mt-0">
                            {{'cancel' | i18n}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
