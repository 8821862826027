<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="twoStepOptionsTitle">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="twoStepOptionsTitle">{{'twoStepOptions' | i18n}}</h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="list-group list-group-flush">
                    <a href="#" appStopClick *ngFor="let p of providers" (click)="choose(p)"
                        class="list-group-item list-group-item-action">
                        <img [src]="'images/two-factor/' + p.type + '.png'" alt="" class="pull-right">
                        <h3>{{p.name}}</h3>
                        {{p.description}}
                    </a>
                    <a href="#" appStopClick class="list-group-item list-group-item-action" (click)="recover()">
                        <h3>{{'recoveryCodeTitle' | i18n}}</h3>
                        {{'recoveryCodeDesc' | i18n}}
                    </a>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'close' | i18n}}</button>
            </div>
        </div>
    </div>
</div>
