<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="folderAddEditTitle">
    <div class="modal-dialog modal-dialog-scrollable modal-sm" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title" id="folderAddEditTitle">{{title}}</h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label for="name">{{'name' | i18n}}</label>
                <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="folder.name" required
                    appAutofocus>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary"
                    data-dismiss="modal">{{'cancel' | i18n}}</button>
                <div class="ml-auto">
                    <button #deleteBtn type="button" (click)="delete()" class="btn btn-outline-danger"
                        appA11yTitle="{{'delete' | i18n}}" *ngIf="editMode" [disabled]="deleteBtn.loading"
                        [appApiAction]="deletePromise">
                        <i class="fa fa-trash-o fa-lg fa-fw" [hidden]="deleteBtn.loading" aria-hidden="true"></i>
                        <i class="fa fa-spinner fa-spin fa-lg fa-fw" [hidden]="!deleteBtn.loading"
                            title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
