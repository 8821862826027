<div class="callout callout-{{calloutStyle}}" [ngClass]="{'clickable': clickable}" role="alert">
    <h3 class="callout-heading" *ngIf="title">
        <i class="fa {{icon}}" *ngIf="icon" aria-hidden="true"></i>
        {{title}}
    </h3>
    <div class="enforced-policy-options" *ngIf="enforcedPolicyOptions">
        {{enforcedPolicyMessage}}
        <ul>
            <li *ngIf="enforcedPolicyOptions?.minComplexity > 0">
                {{'policyInEffectMinComplexity' | i18n : getPasswordScoreAlertDisplay()}}
            </li>
            <li *ngIf="enforcedPolicyOptions?.minLength > 0">
                {{'policyInEffectMinLength' | i18n : enforcedPolicyOptions?.minLength.toString()}}
            </li>
            <li *ngIf="enforcedPolicyOptions?.requireUpper">
                {{'policyInEffectUppercase' | i18n}}</li>
            <li *ngIf="enforcedPolicyOptions?.requireLower">
                {{'policyInEffectLowercase' | i18n}}</li>
            <li *ngIf="enforcedPolicyOptions?.requireNumbers">
                {{'policyInEffectNumbers' | i18n}}</li>
            <li *ngIf="enforcedPolicyOptions?.requireSpecial">
                {{'policyInEffectSpecial' | i18n : '!@#$%^&*'}}</li>
        </ul>
    </div>
    <ng-content></ng-content>
</div>
