<div class="page-header">
    <h1>{{'exposedPasswordsReport' | i18n}}</h1>
</div>
<p>{{'exposedPasswordsReportDesc' | i18n}}</p>
<button type="button" class="btn btn-primary btn-submit" [disabled]="loading" (click)="load()">
    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
    <span>{{'checkExposedPasswords' | i18n}}</span>
</button>
<div class="mt-4" *ngIf="hasLoaded">
    <app-callout type="success" title="{{'goodNews' | i18n}}" *ngIf="!ciphers.length">
        {{'noExposedPasswords' | i18n}}
    </app-callout>
    <ng-container *ngIf="ciphers.length">
        <app-callout type="danger" title="{{'exposedPasswordsFound' | i18n}}">
            {{'exposedPasswordsFoundDesc' | i18n : (ciphers.length | number)}}
        </app-callout>
        <table class="table table-hover table-list table-ciphers">
            <tbody>
                <tr *ngFor="let c of ciphers">
                    <td class="table-list-icon">
                        <app-vault-icon [cipher]="c"></app-vault-icon>
                    </td>
                    <td class="reduced-lh wrap">
                        <ng-container *ngIf="!organization || canManageCipher(c) ; else cantManage">
                            <a href="#" appStopClick (click)="selectCipher(c)" title="{{'editItem' | i18n}}">{{c.name}}</a>
                        </ng-container>
                        <ng-template #cantManage>
                            <span>{{c.name}}</span>
                        </ng-template>
                        <ng-container *ngIf="!organization && c.organizationId">
                            <i class="fa fa-cube" appStopProp title="{{'shared' | i18n}}" aria-hidden="true"></i>
                            <span class="sr-only">{{'shared' | i18n}}</span>
                        </ng-container>
                        <ng-container *ngIf="c.hasAttachments">
                            <i class="fa fa-paperclip" appStopProp title="{{'attachments' | i18n}}"
                                aria-hidden="true"></i>
                            <span class="sr-only">{{'attachments' | i18n}}</span>
                        </ng-container>
                        <br>
                        <small>{{c.subTitle}}</small>
                    </td>
                    <td class="text-right">
                        <span class="badge badge-warning">
                            {{'exposedXTimes' | i18n : (exposedPasswordMap.get(c.id) | number)}}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<ng-template #cipherAddEdit></ng-template>
