<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
    <div class="row justify-content-md-center mt-5">
        <div class="col-4">
            <p class="lead text-center mb-4">{{'updateMasterPassword' | i18n}}</p>
            <div class="card d-block">
                <div class="card-body">
                    <app-callout type="warning">{{'updateMasterPasswordWarning' | i18n}}
                    </app-callout>
                    <div class="form-group">
                        <app-callout type="info" [enforcedPolicyOptions]="enforcedPolicyOptions"
                            *ngIf="enforcedPolicyOptions">
                        </app-callout>
                        <label for="masterPassword">{{'masterPass' | i18n}}</label>
                        <div class="d-flex">
                            <div class="w-100">
                                <input id="masterPassword" type="{{showPassword ? 'text' : 'password'}}"
                                    name="MasterPasswordHash" class="text-monospace form-control mb-1"
                                    [(ngModel)]="masterPassword" (input)="updatePasswordStrength()" required
                                    appInputVerbatim>
                                <app-password-strength [score]="masterPasswordScore" [showText]="true">
                                </app-password-strength>
                            </div>
                            <div>
                                <button type="button" class="ml-1 btn btn-link"
                                    appA11yTitle="{{'toggleVisibility' | i18n}}" (click)="togglePassword(false)">
                                    <i class="fa fa-lg" aria-hidden="true"
                                        [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                                </button>
                                <div class="progress-bar invisible"></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="masterPasswordRetype">{{'reTypeMasterPass' | i18n}}</label>
                        <div class="d-flex">
                            <input id="masterPasswordRetype" type="{{showPassword ? 'text' : 'password'}}"
                                name="MasterPasswordRetype" class="text-monospace form-control"
                                [(ngModel)]="masterPasswordRetype" required appInputVerbatim>
                            <button type="button" class="ml-1 btn btn-link" appA11yTitle="{{'toggleVisibility' | i18n}}"
                                (click)="togglePassword(true)">
                                <i class="fa fa-lg" aria-hidden="true"
                                    [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="hint">{{'masterPassHint' | i18n}}</label>
                        <input id="hint" class="form-control" type="text" name="Hint" [(ngModel)]="hint">
                        <small class="form-text text-muted">{{'masterPassHintDesc' | i18n}}</small>
                    </div>
                    <hr>
                    <div class="d-flex">
                        <button type="submit" class="btn btn-primary btn-block btn-submit" [disabled]="form.loading">
                            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                            <span>{{'submit' | i18n}}</span>
                        </button>
                        <button type="button" class="btn btn-outline-secondary btn-block ml-2 mt-0" (click)="logOut()">
                            {{'logOut' | i18n}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
