<ng-container *ngIf="vault">
    <p *ngIf="!loaded" class="text-muted">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
        <span class="sr-only">{{'loading' | i18n}}</span>
    </p>
    <ng-container *ngIf="loaded">
        <ul class="fa-ul card-ul carets" *ngIf="providers && providers.length">
            <li *ngFor="let p of providers">
                <a [routerLink]="['/providers', p.id]" class="text-body">
                    <i class="fa-li fa fa-caret-right" aria-hidden="true"></i> {{p.name}}
                    <ng-container *ngIf="!p.enabled">
                        <i class="fa fa-exclamation-triangle text-danger" title="{{'providerIsDisabled' | i18n}}"
                            aria-hidden="true"></i>
                        <span class="sr-only">{{'providerIsDisabled' | i18n}}</span>
                    </ng-container>
                </a>
            </li>
        </ul>
    </ng-container>
</ng-container>
<ng-container *ngIf="!vault">
    <app-navbar></app-navbar>
    <div class="container page-content">
        <div class="page-header d-flex">
            <h1>{{'providers' | i18n}}</h1>
        </div>
        <p *ngIf="!loaded" class="text-muted">
            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
            <span class="sr-only">{{'loading' | i18n}}</span>
        </p>
        <ng-container *ngIf="loaded">
            <table class="table table-hover table-list" *ngIf="providers && providers.length">
                <tbody>
                    <tr *ngFor="let p of providers">
                        <td width="30">
                            <app-avatar [data]="p.name" size="25" [circle]="true" [fontSize]="14"></app-avatar>
                        </td>
                        <td>
                            <a href="#" [routerLink]="['/providers', p.id]">{{p.name}}</a>
                            <ng-container *ngIf="!p.enabled">
                                <i class="fa fa-exclamation-triangle text-danger"
                                    title="{{'providerIsDisabled' | i18n}}" aria-hidden="true"></i>
                                <span class="sr-only">{{'providerIsDisabled' | i18n}}</span>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
    <app-footer></app-footer>
</ng-container>
