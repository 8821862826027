<div class="page-header">
    <h1>{{'myProvider' | i18n}}</h1>
</div>
<div *ngIf="loading">
    <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
    <span class="sr-only">{{'loading' | i18n}}</span>
</div>
<form *ngIf="provider && !loading" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="name">{{'providerName' | i18n}}</label>
                <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="provider.name"
                    [disabled]="selfHosted">
            </div>
            <div class="form-group">
                <label for="billingEmail">{{'billingEmail' | i18n}}</label>
                <input id="billingEmail" class="form-control" type="text" name="BillingEmail"
                    [(ngModel)]="provider.billingEmail" [disabled]="selfHosted">
            </div>
        </div>
        <div class="col-6">
            <app-avatar data="{{provider.name}}" dynamic="true" size="75" fontSize="35"></app-avatar>
        </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
        <span>{{'save' | i18n}}</span>
    </button>
</form>
