<ng-container *ngIf="(isPaging() ? pagedCiphers : ciphers) as filteredCiphers">
    <table class="table table-hover table-list table-ciphers" *ngIf="filteredCiphers.length" infiniteScroll
        [infiniteScrollDistance]="1" [infiniteScrollDisabled]="!isPaging()" (scrolled)="loadMore()">
        <tbody>
            <tr *ngFor="let c of filteredCiphers">
                <td (click)="checkCipher(c)" class="table-list-checkbox">
                    <input type="checkbox" [(ngModel)]="c.checked" appStopProp>
                </td>
                <td (click)="checkCipher(c)" class="table-list-icon">
                    <app-vault-icon [cipher]="c"></app-vault-icon>
                </td>
                <td (click)="checkCipher(c)" class="reduced-lh wrap">
                    <a href="#" appStopClick appStopProp (click)="selectCipher(c)"
                        title="{{'editItem' | i18n}}">{{c.name}}</a>
                    <ng-container *ngIf="!organization && c.organizationId">
                        <i class="fa fa-cube" appStopProp title="{{'shared' | i18n}}" aria-hidden="true"></i>
                        <span class="sr-only">{{'shared' | i18n}}</span>
                    </ng-container>
                    <ng-container *ngIf="c.hasAttachments">
                        <i class="fa fa-paperclip" appStopProp title="{{'attachments' | i18n}}" aria-hidden="true"></i>
                        <span class="sr-only">{{'attachments' | i18n}}</span>
                        <ng-container *ngIf="showFixOldAttachments(c)">
                            <i class="fa fa-exclamation-triangle text-warning" appStopProp
                                title="{{'attachmentsNeedFix' | i18n}}" aria-hidden="true"></i>
                            <span class="sr-only">{{'attachmentsNeedFix' | i18n}}</span>
                        </ng-container>
                    </ng-container>
                    <br>
                    <small appStopProp>{{c.subTitle}}</small>
                </td>
                <td class="table-list-options">
                    <div class="dropdown" appListDropdown>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            appA11yTitle="{{'options' | i18n}}">
                            <i class="fa fa-cog fa-lg" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <ng-container *ngIf="c.type === cipherType.Login && !c.isDeleted">
                                <a class="dropdown-item" href="#" appStopClick
                                    (click)="copy(c, c.login.username, 'username', 'Username')">
                                    <i class="fa fa-fw fa-clone" aria-hidden="true"></i>
                                    {{'copyUsername' | i18n}}
                                </a>
                                <a class="dropdown-item" href="#" appStopClick
                                    (click)="copy(c, c.login.password, 'password', 'Password')" *ngIf="c.viewPassword">
                                    <i class="fa fa-fw fa-clone" aria-hidden="true"></i>
                                    {{'copyPassword' | i18n}}
                                </a>
                                <a class="dropdown-item" href="#" appStopClick (click)="copy(c, c.login.totp, 'verificationCodeTotp', 'TOTP')"
                                    *ngIf="displayTotpCopyButton(c)">
                                    <i class="fa fa-fw fa-clone" aria-hidden="true"></i>
                                    {{'copyVerificationCode' | i18n}}
                                </a>
                                <a class="dropdown-item" href="#" appStopClick *ngIf="c.login.canLaunch"
                                    (click)="launch(c.login.launchUri)">
                                    <i class="fa fa-fw fa-share-square-o" aria-hidden="true"></i>
                                    {{'launch' | i18n}}
                                </a>
                            </ng-container>
                            <a class="dropdown-item" href="#" appStopClick (click)="attachments(c)">
                                <i class="fa fa-fw fa-paperclip" aria-hidden="true"></i>
                                {{'attachments' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick
                                *ngIf="((!organization && !c.organizationId) || organization) && !c.isDeleted"
                                (click)="clone(c)">
                                <i class="fa fa-fw fa-files-o" aria-hidden="true"></i>
                                {{'clone' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick
                                *ngIf="!organization && !c.organizationId && !c.isDeleted" (click)="share(c)">
                                <i class="fa fa-fw fa-arrow-circle-o-right" aria-hidden="true"></i>
                                {{'moveToOrganization' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick *ngIf="c.organizationId && !c.isDeleted"
                                (click)="collections(c)">
                                <i class="fa fa-fw fa-cubes" aria-hidden="true"></i>
                                {{'collections' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick *ngIf="c.organizationId && accessEvents"
                                (click)="events(c)">
                                <i class="fa fa-fw fa-file-text-o" aria-hidden="true"></i>
                                {{'eventLogs' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick (click)="restore(c)" *ngIf="c.isDeleted">
                                <i class="fa fa-fw fa-undo" aria-hidden="true"></i>
                                {{'restore' | i18n}}
                            </a>
                            <a class="dropdown-item text-danger" href="#" appStopClick (click)="delete(c)">
                                <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>
                                {{(c.isDeleted ? 'permanentlyDelete' : 'delete') | i18n}}
                            </a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="no-items" *ngIf="!filteredCiphers.length">
        <ng-container *ngIf="!loaded">
            <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
            <span class="sr-only">{{'loading' | i18n}}</span>
        </ng-container>
        <ng-container *ngIf="loaded">
            <p>{{'noItemsInList' | i18n}}</p>
            <button (click)="addCipher()" class="btn btn-outline-primary" *ngIf="showAddNew">
                <i class="fa fa-plus fa-fw"></i>{{'addItem' | i18n}}</button>
        </ng-container>
    </div>
</ng-container>
