<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="bulkTitle">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="bulkTitle">
                    {{'confirmUsers' | i18n}}
                </h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="card-body text-center" *ngIf="loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    {{'loading' | i18n}}
                </div>
                <app-callout type="danger" *ngIf="filteredUsers.length <= 0">
                    {{'noSelectedUsersApplicable' | i18n}}
                </app-callout>
                <app-callout type="error" *ngIf="error">
                    {{error}}
                </app-callout>
                <ng-container *ngIf="!loading && !done">
                    <p>
                        {{'fingerprintEnsureIntegrityVerify' | i18n}}
                        <a href="https://help.bitwarden.com/article/fingerprint-phrase/" target="_blank" rel="noopener">
                            {{'learnMore' | i18n}}</a>
                    </p>
                    <table class="table table-hover table-list">
                        <thead>
                            <tr>
                                <th colspan="2">{{'user' | i18n}}</th>
                                <th>{{'fingerprint' | i18n}}</th>
                            </tr>
                        </thead>
                        <tr *ngFor="let user of filteredUsers">
                            <td width="30">
                                <app-avatar [data]="user | userName" [email]="user.email" size="25" [circle]="true"
                                    [fontSize]="14"></app-avatar>
                            </td>
                            <td>
                                {{user.email}}
                                <small class="text-muted d-block" *ngIf="user.name">{{user.name}}</small>
                            </td>
                            <td>
                                {{fingerprints.get(user.id)}}
                            </td>
                        </tr>
                        <tr *ngFor="let user of excludedUsers">
                            <td width="30">
                                <app-avatar [data]="user | userName" [email]="user.email" size="25" [circle]="true"
                                    [fontSize]="14"></app-avatar>
                            </td>
                            <td>
                                {{user.email}}
                                <small class="text-muted d-block" *ngIf="user.name">{{user.name}}</small>
                            </td>
                            <td>
                                {{'bulkFilteredMessage' | i18n}}
                            </td>
                        </tr>
                    </table>
                </ng-container>
                <ng-container *ngIf="!loading && done">
                    <table class="table table-hover table-list">
                        <thead>
                            <tr>
                                <th colspan="2">{{'user' | i18n}}</th>
                                <th>{{'status' | i18n}}</th>
                            </tr>
                        </thead>
                        <tr *ngFor="let user of filteredUsers">
                            <td width="30">
                                <app-avatar [data]="user | userName" [email]="user.email" size="25" [circle]="true"
                                    [fontSize]="14"></app-avatar>
                            </td>
                            <td>
                                {{user.email}}
                                <small class="text-muted d-block" *ngIf="user.name">{{user.name}}</small>
                            </td>
                            <td *ngIf="statuses.has(user.id)">
                                {{statuses.get(user.id)}}
                            </td>
                            <td *ngIf="!statuses.has(user.id)">
                                {{'bulkFilteredMessage' | i18n}}
                            </td>
                        </tr>
                    </table>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" *ngIf="!done" [disabled]="loading" (click)="submit()">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'confirm' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'close' | i18n}}</button>
            </div>
        </div>
    </div>
</div>
