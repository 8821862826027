<app-callout type="warning">{{'loggedOutWarning' | i18n}}</app-callout>
<app-callout type="info" [enforcedPolicyOptions]="enforcedPolicyOptions" *ngIf="enforcedPolicyOptions">
</app-callout>

<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="currentMasterPassword">{{'currentMasterPass' | i18n}}</label>
                <input id="currentMasterPassword" type="password" name="MasterPasswordHash" class="form-control"
                    [(ngModel)]="currentMasterPassword" required appInputVerbatim>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="newMasterPassword">{{'newMasterPass' | i18n}}</label>
                <input id="newMasterPassword" type="password" name="NewMasterPasswordHash" class="form-control mb-1"
                    [(ngModel)]="masterPassword" (input)="updatePasswordStrength()" required appInputVerbatim
                    autocomplete="new-password">
                <app-password-strength [score]="masterPasswordScore" [showText]="true"></app-password-strength>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label for="masterPasswordRetype">{{'confirmNewMasterPass' | i18n}}</label>
                <input id="masterPasswordRetype" type="password" name="MasterPasswordRetype" class="form-control"
                    [(ngModel)]="masterPasswordRetype" required appInputVerbatim autocomplete="new-password">
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="rotateEncKey" name="RotateEncKey"
                [(ngModel)]="rotateEncKey" (change)="rotateEncKeyClicked()">
            <label class="form-check-label" for="rotateEncKey">
                {{'rotateAccountEncKey' | i18n}}
            </label>
        </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
        <span>{{'changeMasterPassword' | i18n}}</span>
    </button>
</form>
