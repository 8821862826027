<app-navbar></app-navbar>
<div class="org-nav" *ngIf="provider">
    <div class="container d-flex">
        <div class="d-flex flex-column">
            <div class="my-auto d-flex align-items-center pl-1">
                <app-avatar [data]="provider.name" size="45" [circle]="true"></app-avatar>
                <div class="org-name ml-3">
                    <span>{{provider.name}}</span>
                    <small class="text-muted">{{'provider' | i18n}}</small>
                </div>
                <div class="ml-3 card border-danger text-danger bg-transparent" *ngIf="!provider.enabled">
                    <div class="card-body py-2">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{'providerIsDisabled' | i18n}}
                    </div>
                </div>
            </div>
            <ul class="nav nav-tabs" *ngIf="showMenuBar">
                <li class="nav-item">
                    <a class="nav-link" routerLink="clients" routerLinkActive="active">
                        <i class="fa fa-university" aria-hidden="true"></i>
                        {{'clients' | i18n}}
                    </a>
                </li>
                <li class="nav-item" *ngIf="showManageTab">
                    <a class="nav-link" [routerLink]="manageRoute" routerLinkActive="active">
                        <i class="fa fa-sliders" aria-hidden="true"></i>
                        {{'manage' | i18n}}
                    </a>
                </li>
                <li class="nav-item" *ngIf="showSettingsTab">
                    <a class="nav-link" routerLink="settings" routerLinkActive="active">
                        <i class="fa fa-cogs" aria-hidden="true"></i>
                        {{'settings' | i18n}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="container page-content">
    <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
