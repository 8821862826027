<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="policiesEditTitle">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title" id="policiesEditTitle">{{'editPolicy' | i18n}} - {{policy.name | i18n}}</h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            
            <div class="modal-body">
                <div class="modal-body" *ngIf="loading">
                    <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span class="sr-only">{{'loading' | i18n}}</span>
                </div>
                <div [hidden]="loading">
                    <p>{{policy.description | i18n}}</p>
                    <ng-template #policyForm></ng-template>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
                    {{'cancel' | i18n}}
                </button>
            </div>
        </form>
    </div>
</div>
